@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Figtree:ital,wght@0,300..900;1,300..900&family=Heebo:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.poppins-medium {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
a {
  text-decoration: none;
}

/* NAVBAR STARTED */

.sidebar {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  background: #39608b;
  z-index: 999;
  padding-bottom: 50px;
  min-height: 100vh;
}
.mobile-sidebar::-webkit-scrollbar {
  width: 8px; /* Dikey scrollbar için */
  height: 8px; /* Yatay scrollbar için */
}

/* Scrollbar'ın arka planını (track) ayarlayın */
.mobile-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Gri renk */
  border-radius: 10px; /* Köşe yuvarlatma */
}

/* Scrollbar'ın tutacağı (thumb) ayarlayın */
.mobile-sidebar::-webkit-scrollbar-thumb {
  background: #888; /* Koyu gri renk */
  border-radius: 10px; /* Köşe yuvarlatma */
  transition: background 0.3s ease;
}

/* Scrollbar tutacağı hover durumu */
.mobile-sidebar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Daha koyu gri */
}

.navbar .megamenu {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
}

/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}
/* NAVBAR FINISHED */

/* NEWS-LAYOUTS-STARTED */
.divResimAlani {
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #f3f3f3;
  width: 100%;
  min-height: 400px;
}

@media (min-width: 991px) {
  .mobile-social-media {
    display: none;
  }
}

@media (max-width: 768px) {
  .mobile-social-media {
    display: inline-block;
    text-align: right;
    padding: 0px 10px 0px 0px;
    margin: -3px 0px 0px 0px;
  }

  .mobile-social-media a.a-whatsapp {
    padding: 0px 20px;
    border-right: solid 1px #626262;
  }

  .mobile-social-media a.a-twitter {
    padding: 0px 20px 0px 10px;
    border-right: solid 1px #626262;
  }

  .mobile-social-media a.a-facebook {
    padding: 0px 0px 0px 10px;
  }

  .mobile-social-media a.a-twitter {
    margin: 0px 10px;
  }

  .mobile-social-media a.a-whatsapp img {
    width: 8%;
  }

  .mobile-social-media a.a-twitter img {
    width: 9%;
    height: 18px;
  }

  .mobile-social-media a.a-facebook img {
    /*width: 3.8%;*/
    height: 18px;
  }
}
/* NEWS-LAYOUTS-FINISHED */

/* YONETIM-KURULU-STARTED */
.kurul-card {
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
/* YONETIM-KURULU-FINISHED */



.preview-img {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.15);
  inset: 0px;
  transition: all 150ms ease-in;
  opacity: 0;
}
.preview-img:hover {
  opacity: 100;
}
.active-preview-img {
  position: absolute;
  background-color: #39608B;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* En fazla 2 satır */
  -webkit-box-orient: vertical;
  overflow: hidden; /* Taşan kısmı gizle */
}

@media (max-width: 576px) {
  .card-text {
    font-size: 11.25rem; /* 1.25rem = text-xl karşılığı */
  }
  .kurul-card {
    height: 350px;
  }
  .kurul-card-wrapper {
    padding: 0 60px 0 60px;
  }
  .kurul-card-shadow {
    margin-top: 15px;
  }
  .footer-logo img {
    width: 100%;
  }
}

@media (min-width: 576px) {

}